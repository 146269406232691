<template>
    <div>
        <page-header v-if="showHeader">
            <div slot="center-box" class="uf uf-ac ml-5">
<!--                <el-input placeholder="请输入搜索内容" class="mr-2" v-model="search"-->
<!--                          @keyup.enter.native="searchClickListener"></el-input>-->
<!--                <el-button type="primary" class="button" icon="el-icon-search" @click="searchClickListener">搜索-->
<!--                </el-button>-->
                <div class="navs uf uf-ac wi00">
                    <!--                <div class="service-class mr-3 pr">行业服务分类</div>-->
                    <div v-for="(item, index) in navs" :key="index" class="nav-li mr-3" :class="{on: index === curNavIndex}"
                         @click="navClick(item, index)">{{ item.name }}
                    </div>
                </div>
            </div>
        </page-header>
        <div class="navs-box">
<!--            <div class="navs uf uf-ac">-->
<!--&lt;!&ndash;                <div class="service-class mr-3 pr">行业服务分类</div>&ndash;&gt;-->
<!--                <div v-for="(item, index) in navs" :key="index" class="nav-li mr-3" :class="{on: index === curNavIndex}"-->
<!--                     @click="navClick(item, index)">{{ item.name }}-->
<!--                </div>-->
<!--            </div>-->
            <div class="trades uf uf-pj" v-if="$route.name === 'industry-chain' || $route.name === 'trades-detail'">
                <div class="trade-li uf uf-ver uf-ac" v-for="(item, index) in trades" :key="index"
                     :class="{on: index === curTradeIndex}" @click="tradesClick(index)">
                    <div class="icon-box uf uf-ac uf-pc" :style="{background: item.color, 'border-color': item.color2}">
                        <i :class="`iconfont ${item.icon}`"></i>
                    </div>
                    <div class="name mt-3 fwb f16">{{ item.name }}</div>
                </div>
            </div>
        </div>
        <!--    <main-navbar :expand-left-menu="expandLeftMenu"></main-navbar>-->
        <template v-if="meta.showBreakCrumb">
            <div class="main-breakcrumb uf uf-ac">
                <div style="margin: auto;width: 1280px">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ name: 'index' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>{{ meta.title }}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </template>
        <el-backtop v-if="currentPage !== 'index'" :right="20"></el-backtop>
    </div>
</template>

<script>
import PageHeader from '../components/page-header'
import routerTransfer from '../utils/routerTransfer'

export default {
    components: {PageHeader},
    props: {
        showNav: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            showLeftMenu: true,
            expandLeftMenu: false,
            showHeader: true,
            curNavIndex: 0,
            navs: [
                {name: '首页', router: 'index'},
                {name: '产业链', router: 'industry-chain'},
                {name: '全部服务', router: 'search'},
                {name: '需求大厅', router: 'demand-hall'},
                {name: '应用示范', router: 'application'},
                {name: '新闻公告', router: 'news'},
            ],
            curTradeIndex: -1,
            trades: [
                {name: '生物医药行业', icon: 'icon-shengwuyiyao', color: '#409EFF', color2: '#9FDCFF'},
                {name: '机械装备行业', icon: 'icon-jixie', color: '#ff6900', color2: '#ffbd73'},
                {name: '汽车行业', icon: 'icon-qiche', color: '#008638', color2: '#9cffd2'},
                {name: '厨电行业', icon: 'icon-youyanji', color: '#9200e5', color2: '#d19fff'},
                {name: '纺织行业', icon: 'icon-duanxiujikouyifu', color: '#c5a500', color2: '#c9c579'},
                {name: '包装行业', icon: 'icon-wuliaobaozhuangdan', color: '#409EFF', color2: '#9FDCFF'},
                {name: '家具行业', icon: 'icon-shafa', color: '#ff6900', color2: '#ffbd73'},
                {name: '生鲜食品行业', icon: 'icon-shuiguo', color: '#008638', color2: '#9cffd2'},
                {name: '电梯行业', icon: 'icon-dianti-xiantiao', color: '#9200e5', color2: '#d19fff'},
                {name: '精密模具行业', icon: 'icon-yewumoban', color: '#409EFF', color2: '#9FDCFF'}
            ],
            meta: {},
            search: ''
        }
    },
    computed: {
        currentPage: {
            get() {
                return this.$store.state.common.currentPage
            },
            set(val) {
                this.$store.commit('common/updateCurrentPage', val)
            }
        },
        keepAliveArr: {
            get() {
                return this.$store.state.common.keepAliveArray
            },
            set(arr) {
                this.$store.commit('common/updateKeepAliveArray', arr)
            }
        }
    },
    watch: {
        $route: 'routeHandle'
    },
    created() {
        this.routeHandle(this.$route)
    },
    methods: {
        navClick(item, index) {
            this.curNavIndex = index
            this.$router.push({name: item.router})
        },
        tradesClick(index) {
            this.curTradeIndex = index
            this.$router.push({name: 'industry-chain', query: {trade: index}})
        },
        routeHandle(route) {
            this.setActiveNav(route.name)
            if(route.name === 'trades-detail' || route.name === 'industry-chain') {
                this.curTradeIndex = Number(route.query.trade || 0)
            }
            let meta = route.matched[1].meta
            this.meta = meta
            this.currentPage = route.name
            this.showLeftMenu = meta.showLeftMenu
            this.expandLeftMenu = meta.expandLeftMenu
            if (this.$route.name === 'implement' || this.$route.name === 'business-portraits') {
                this.showHeader = false
            } else {
                this.showHeader = true
            }
        },
        setActiveNav(n) {
            if (n === 'index') {
                this.curNavIndex = 0
                this.curTradeIndex = -1
            } else if (n === 'search' || n === 'service' || n === 'payment' || n === 'shoppingCart') {
                this.curNavIndex = 2
            } else if (n === 'industry-chain') {
                this.curNavIndex = 1
            } else if (n === 'demand-hall') {
                this.curNavIndex = 3
            } else if (n === 'application') {
                this.curNavIndex = 4
            } else if (n === 'news') {
                this.curNavIndex = 5
            } else {
                this.curNavIndex = -1
            }
        },
        searchClickListener() {
            // this.keepAliveArr = []
            this.$router.push({name: 'search', params: {serviceName: this.search ? this.search : null}})
            routerTransfer.$emit('searchEvent', {serviceName: this.search ? this.search : null})
        }
    }
}
</script>

<style scoped lang="scss">
.navs {
  .service-class {
    padding: 16px 30px;
    margin-right: 20px;
    background: white;
    border-radius: 8px 8px 0 0;
    font-weight: bold;
    box-shadow: 0 0 20px rgba(0, 27, 42, 0.1);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 16px;
      bottom: -8px;
      background: white;
      z-index: 2;
    }
  }

  .nav-li {
    padding: 6px 30px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      background: #eaeaea;
    }
  }

  .on, .on:hover {
    color: white;
    background: #ff9123;
  }
}
.navs-box {
  width: 1325px;
  margin: 20px auto 0;
  .trades {
    padding: 15px 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 27, 42, 0.1);

    .trade-li {
      padding: 10px 0;
      width: 130px;
      margin: 0 4px;
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid transparent;
      transition: all .3s ease;

      &:hover {
        background: #eeeeee;
      }

      .icon-box {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 4px solid;

        .iconfont {
          color: white;
          font-size: 28px;
        }
      }
    }

    .on, .on:hover {
      background: #e8e8e8;
      border-color: #ff9123;
    }
  }
}

.main-breakcrumb {
  background: #fff;
  line-height: 45px;
  height: 45px;
  width: 100%;
  padding: 0 45px;
  border-top: 1px solid #EBEEF5;
}
</style>
