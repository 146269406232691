<template>
  <div class="hi100">
    <keep-alive :include="keepAliveArr">
      <router-view></router-view>
    </keep-alive>
<!--    <router-view></router-view>-->
  </div>
</template>

<script>
  export default {

    computed: {
      keepAliveArr () {
        return this.$store.state.common.keepAliveArray
      }
    },
    created () {
    },
    data () {
      return {
      }
    },
    // computed: {
    //   keepAliveArray: {
    //     get() {
    //       return this.$store.state.common.keepAliveArray
    //     },
    //     set(arr) {
    //       this.$store.commit('common/updateKeepAliveArray',arr)
    //     }
    //   }
    // },
    methods: {
    }
  }
</script>

<style scoped>

</style>
